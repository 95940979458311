<template>
  <div class="activity-container">
    <div class="activity-contents">
      <div class="activity-top-items">
        <h3 class="selected-radio">{{ selected }}</h3>
        <div style="position: relative;">
          <input class="search-input" type="text" v-model="search" placeholder="Search here" />
          <div class="search-icon"></div>
        </div>
        <p class="clear-all">Clear All</p>
      </div>
      <div class="display-contents">
        <div class="content-box" v-for="(data, i) in demoContent" :key="i">
          <div class="image-container">
            <!-- <img :src="data.image" alt /> -->
          </div>
          <div class="details-container">
            <h4>{{ data.title }}</h4>
            <div>
              <div class="content-tags">
                <p class="content-para">{{ data.genre }}</p>
                <p class="content-para">{{ data.lang }}</p>
                <p class="content-para">{{ data.year }}</p>
                <p class="content-para">{{ data.agerate }}</p>
              </div>
              <div class="activity-tags">
                <p>{{ data.duration }}</p>
                <p>{{ data.views }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="radio-buttons">
      <div class="radio-divs" v-for="(label, index) in radiolabels" :key="index">
        <!-- <input class="radio-custom" type="radio" v-model="selected" :value="label.name" />
        <label :for="label.name">{{label.name}}</label>-->
        <label class="containers">
          <input
            type="radio"
            class="hidden-radio"
            v-model="selected"
            name="radio"
            :value="label.name"
          />
          <span class="label-name">{{ label.name }}</span>
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      selected: "Watch History",
      demoContent: [],
      radiolabels: []
    };
  },
  mounted() {
    this.demoContent = [
      {
        // image: require("@/assets/Images/img1.jpg"),
        title: "Game Starts",
        duration: "1 hr 20mins",
        views: "2K views",
        genre: "Thriller",
        lang: "English",
        agerate: "16+",
        year: "2020"
      },
      {
        // image: require("@/assets/Images/img1.jpg"),
        title: "Game Starts",
        duration: "1 hr 20mins",
        views: "2K views",
        genre: "Thriller",
        lang: "English",
        agerate: "16+",
        year: "2020"
      }
    ];
    this.radiolabels = [
      { name: "Watch History" },
      { name: "Search History" },
      { name: "Liked Videos" },
      { name: "Unliked Videos" },
      { name: "Shared Contents" }
    ];
  }
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";

.activity-container {
  display: grid;
  grid-template-columns: 70% 30%;
}
.activity-contents {
  padding: 1.5rem 1rem;
  .selected-radio {
    color: $clr-light-gd2;
  }
  .search-input {
    background-color: transparent;
    border-bottom: 1px solid $clr-light-gd4 !important;
    border: none;
    color: $clr-light-gd4;
    padding-bottom: 3px;
    &:focus {
      outline: none;
    }
  }
  .search-icon {
    background-image: url("../../../../assets/icons/Search.svg");
    background-repeat: no-repeat;
    position: absolute;
    height: 13px;
    width: 13px;
    top: 18%;
    right: -11%;
  }
  .clear-all {
    color: $clr-light-gd4;
    font-size: 0.8rem;
    font-weight: 600;
    text-align: end;
  }
  .content-box {
    display: flex;
    margin: 1.5rem 0rem;
    .details-container {
      padding-left: 1rem;
    }
    .image-container {
      img {
        border-radius: 5px;
        width: 200px;
        height: 100px;
      }
    }
    h4 {
      color: $clr-light-gd2;
    }
    .content-tags {
      display: flex;
      .content-para {
        font-size: 14px;
        //color: $clr-light-gd2;
        font-weight: 600;
        padding-right: 5px;
        line-height: 20px;
      }
    }
    .activity-tags {
      display: flex;
      font-size: 12px;
      color: $clr-light-gd4;
      padding-top: 5px;
      p {
        padding-right: 5px;
      }
    }
  }
}
.display-contents {
  padding: 3rem 0rem;
}
.activity-top-items {
  display: grid;
  grid-template-columns: 39% 25% 32%;
  align-items: center;
}
.radio-buttons {
  grid-column: 2 / span 10;
  padding: 1.8rem;
  background-color: $clr-dark-gd8;
  color: $clr-light-gd4;
  .radio-divs {
    margin: 1rem 0rem;
    display: flex;
    align-items: center;
    label {
      font-size: 0.9rem;
    }
    .radio-custom {
      width: none !important;
      &:checked ~ label {
        color: $clr-light-gd3;
      }
    }
  }
}
</style>
