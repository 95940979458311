<template>
  <div class="settings-section">
    <ul class="profile-tabs">
      <li
        v-for="(tab, index) in tabList"
        :key="index || tabList.length"
        @click="clicked(tab.id)"
        :class="['tab-btn', { active: componentName === tab.id }]"
      >
      <div  class="item-title">
        <CustomAvatar :avatarType="tab.id"></CustomAvatar>
        <div>
          <p class="item-name">{{ $t(tab.name) }}</p>
          <p class="item-description">{{ $t(tab.description) }}</p>
        </div>
      </div>
      <span class="item-icon">
        <svg width="9" height="15" viewBox="0 0 9 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.35008 14.1667L0.166748 12.9833L5.65008 7.49999L0.166748 2.01666L1.35008 0.833328L8.01675 7.49999L1.35008 14.1667Z" fill="white"/>
        </svg>
      </span>
      </li>
    </ul>
    <div class="gradient-line"></div>
    <div class="component-contains">
      <component
        :is="componentName"
        :locale="locale"
        :isCardUpdate="isCardUpdate"
        :cardUpdateDetailState="cardUpdateDetailState"
        :cardUpdateStatus="cardUpdateStatus"
        @gatewayList="setGatewayList"
      ></component>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Utility from "@/mixins/Utility.js";

export default {
  props: {
    locale: {
      type: String,
    },

    paymentDetail: {
      type: Object,
    },

    isCardUpdate: {
      type: Boolean,
    },

    cardUpdateDetailState: {
      type: Object,
    },

    cardUpdateStatus: {
      type: Object,
    },
  },
  data() {
    return {
      tabList: [
        // { name: "Plans", id: "Plans" },
        // { name: "Tickets", id: "Tickets" },
        // { name: "Devices", id: "Devices" },
      ],
      componentName: "",
      localDisplayLang: null,
      gatewayListArr: [],
      subscriberDetails: null,
    };
  },

  watch: {
    subscriptionList(val) {
      if (val && val.length) {
        val.forEach((ele) => {
          if (
            ele.gwprovider == "CHECKOUT" &&
            this.profileid === this.subscriberDetails.profileid &&
            !JSON.stringify(this.tabList).includes(JSON.stringify({ name: "Update Card", id: "CardUpdate" }))
          ) {
            this.tabList.push({ name: "Update Card", id: "CardUpdate" });
            return;
          }
        });
      }
    },
  },

  computed: {
    ...mapGetters(["appConfig", "subscriptionList", "getIsManageCards", "profileid", "subscriberid"]),
  },

  created() {
    this.getUserInfo();

    this.setupTabList();
  },

  mounted() {

    // if (this.appConfig.featureEnabled.isPlanEnable) {
    //     this.tabList.unshit({ name: "Plans", id: "plans" });
    //   }

    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    let itemList = this.$route.params.listItem;
    this.componentName = itemList || this.tabList[0].id;

    if (this.isCardUpdate && this.cardUpdateDetailState) {
      this.componentName = "CardUpdate";
    }
  },

  methods: {
    ...mapMutations(["setSubscriptionList"]),

    clicked(data) {
      this.componentName = data;
      let currentLanguage = this.getCurrentLanguageUrlBase();

      this.$router.push({name: "Profile", params: { lang: currentLanguage, listItem: data }})

    },

    getUserInfo() {
      this.isSubProfiles = false;
      if (localStorage.getItem("subscriberDetails")) {
        this.subscriberDetails = JSON.parse(localStorage.getItem("subscriberDetails")).data;
      }

      this.getCurrentProfileDetails();
    },

    getCurrentProfileDetails() {
      this.subscriberProfileDetails = localStorage.getItem("subscriberProfileDetails")
        ? JSON.parse(localStorage.getItem("subscriberProfileDetails"))
        : null;
    },

    setupTabList() {
      // Condition for admin Profile
      let kidsMode = (localStorage.getItem("isKidsMode") === 'true');
      if (this.profileid === this.subscriberid) {
        this.tabList = [
          { name: "Plans", id: "plans", description:'Manage Subscription'},
          { name: "Tickets", id: "tickets", description:'Help & Center' },
          { name: "Parental Controls", id: "parentalcontrol", description:'Parental Lock'},
          { name: "My Devices", id: "devices", description:'Manage Devices' },
          { name: "My Space", id: "mySpace", description:'Manage your profile' },
          // { name: "Feedback",id:"feedback"},
          // { name: "Purchases", id:"purchases"}

        ];
      } else {
        if (kidsMode) {
          this.tabList = [{ name: "Tickets", id: "tickets" }];
        } else {
          this.tabList = [
          { name: "Tickets", id: "tickets" },
          { name: "Parental Control", id: "parentalcontrol" },
          { name: "Feedback",id:"feedback"},
          { name: "My Space", id: "mySpace" },
        ];
        }
      }

      this.addManageCardsToList();

      this.addDevicesListToMenu();
    },

    setGatewayList(val) {
      this.gatewayListArr = val;
    },

    addManageCardsToList() {
      if (this.profileid === this.subscriberDetails.profileid) {
        if (this.subscriptionList && this.subscriptionList.length) {
          this.subscriptionList.forEach((ele) => {
            if (ele.gwprovider == "CHECKOUT") {
              this.tabList.push({ name: "Update Card", id: "CardUpdate" });
              return;
            }
          });
        }
      }
    },

    addDevicesListToMenu() {
      if (this.appConfig.featureEnabled.isDevicesListEnabled && this.profileid === this.subscriberDetails.profileid) {
        this.tabList.push({ name: "Devices", id: "Devices" });
      }
    },
  },
  components: {
    plans: () => import(/* webpackChunkName: "tabs-plan" */ "./Tabs/Plans.vue"),
    devices: () => import(/* webpackChunkName: "tabs-device" */ "./Tabs/Devices.vue"),
    Bills: () => import(/* webpackChunkName: "tabs" */ "./Tabs/Bills.vue"),
    purchases: () => import(/* webpackChunkName: "tabs" */ "./Tabs/Purchases.vue"),
    tickets: () => import(/* webpackChunkName: "tabs-tickets" */ "./Tabs/Tickets.vue"),
    ActivityLog: () => import(/* webpackChunkName: "tabs" */ "./Tabs/Activities.vue"),
    Settings: () => import(/* webpackChunkName: "tabs" */ "./Tabs/Settings.vue"),
    CardUpdate: () => import(/* webpackChunkName: "tabs" */ "./CardUpdate.vue"),
    parentalcontrol: () => import("./Tabs/ParentalControl.vue"),
    feedback: () => import(/* webpackChunkName: "StarRating" */ "./Tabs/StarRating.vue"),
    mySpace: () => import(/* webpackChunkName: "myspace" */ "@/components/Profile/Subscriber/SubscriberDetails.vue"),
    CustomAvatar: () => import(/* webpackChunkName: "customAvatar" */ "./icons.vue"),
  },
  mixins: [Utility],

};
</script>

<style scoped lang="scss">
@import "@/sass/_variables.scss";
.settings-section {
  display: flex;
  gap: 0.4rem;
  justify-content: space-between;
  .profile-tabs {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.7rem;
    //flex-wrap: wrap;
    font-family: $font-regular;
    //background-color: $clr-dark-gd4;
    padding: 1.5rem 0;
    //border-top-left-radius: 15px;
    //border-top-right-radius: 15px;
    .tab-btn {
      width: fit-content;
      padding: 1rem;
      border-radius: 5px;
      background: transparent;
      font-size: 0.95rem;
      font-weight: 600;
      color: $clr-parental-txt;
      cursor: pointer;
      display: flex;
      align-items: center;
      width: clamp(2rem, 100%, 95%);
      margin: 0 auto;
      justify-content: space-between;
      .item-icon {
        align-self: center;
      }
      .item-title {
        align-self: center;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        .item-description {
          font-size: 0.6rem;
        }
        .item-name {
          color: #FFFFFF;
          font-size: clamp(0.9rem, 3vw - 1rem, 1rem);
        }
      }
    }

    .active {
      //color: $clr-primary-button-text-color-common;
      background: $clr-device-details-bg;
      border-radius: 0.5rem;
      border: 1px solid #ccc;
      //&:hover {
          // color: $clr-dark-gd3;
       //   background: $clr-primary-button-common-hover;
       //   color: $clr-login-signup-container;
      //}
    }
  }
  .gradient-line {
    width: 3px;
    height: 100vh;
    background: linear-gradient(180deg,rgba(112,122,148,0),#707a94 50%,rgba(112,122,148,0));
  }
  .component-contains {
    padding: 20px 0 50px 0;
    margin-top: 0.3rem;
    font-family: $font-regular;
    flex-grow: 1;
    width: 70%;
    //background-color: $clr-dark-gd5;
    //border-bottom-left-radius: 15px;
    //border-bottom-right-radius: 15px;
  }
}

@media only screen and (max-width: 678px) {
  .settings-section {
    flex-direction: column;
    .profile-tabs {
      display: flex;
      flex-direction: row;        
      width: auto;
      overflow-x: auto;
      .tab-btn {
        padding: 0.5rem;
        .item-icon {
          display: none;
        }
        .item-title {
          .item-description {
            display: none;
          }
          .item-name {
            text-wrap: nowrap;
          }
        }
      }
    }
    .gradient-line {
      display: none;
    }
    .component-contains {
      padding: 0px;
      flex-grow: 1;
    }
  }
}
</style>
