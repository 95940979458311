<template>
  <div class="card-update">
    <!-- Stepper -->

    <div class="card-update__container">
      <Stepper
        :key="isCaptchaVerificationComplete || isCardUpdationComplete"
        :verifyComplete="isCaptchaVerificationComplete"
        :cardUpdateComplete="isCardUpdationComplete"
      />
    </div>

    <!-- Main component -->

    <br />

    <div class="main-component card-update__container">
      <component
        v-if="currentComponent"
        :is="currentComponent"
        @switch="switchComponent"
        :existingCardDetails="existingCardDetails"
        :isUpdateFailed="isUpdateFailed"
        :cardUpdateStatus="cardUpdateStatus"
      ></component>

      <!-- <CardUpdateStatus /> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isCardUpdate: {
      type: Boolean,
    },

    cardUpdateDetailState: {
      type: Object,
    },

    cardUpdateStatus: {
      type: Object,
    },
  },

  data() {
    return {
      currentComponent: null,
      existingCardDetails: null,
      updateStatus: null,
      isCaptchaVerificationComplete: false,
      isCardUpdationComplete: false,
      isUpdateFailed: false,
    };
  },

  mounted() {
    this.currentComponent = "VerifyCaptcha";

    if (this.isCardUpdate && this.cardUpdateStatus) {
      this.currentComponent = "CardUpdateStatus";
      this.isCaptchaVerificationComplete = true;
      this.isCardUpdationComplete = true;
    }
  },

  methods: {
    switchComponent(data) {
      
      this.currentComponent = data.switchTo;

      if (data.switchTo == "UpdateCardDetails") {
        this.existingCardDetails = data.response;
        this.isCaptchaVerificationComplete = true;
      } else if (data.switchTo == "CardUpdateStatus") {
        this.updateStatus = data.response;
        this.isCardUpdationComplete = true;
      }
    },
  },

  components: {
    Stepper: () => import("./CardUpdateTabs/Stepper.vue"),
    VerifyCaptcha: () => import("./CardUpdateTabs/VerifyCaptcha.vue"),
    UpdateCardDetails: () => import("./CardUpdateTabs/UpdateCardDetails.vue"),
    CardUpdateStatus: () => import("./CardUpdateTabs/CardUpdateStatus.vue"),
  },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.card-update {
  font-family: $font-regular;
  color: $clr-light-gd1;

  &__container {
    padding: 1rem;
  }

  .main-component {
    margin: 1rem 1.5rem;
  }
}

@media only screen and (max-width:500px) {
  .card-update {
    .stepper {
      width: 100%;
      &__step-container {
        max-width: 100%;
      }
    }

    .main-component {
      margin: 0.5rem 0.75rem;
    }
  }
}
</style>
