<template>
  <div class="settings-tab">
    <!-- <h3>Settings</h3> -->
    <div class="radio-type">
      <h4>Display Language</h4>
      <div class="radio-box">
        <div class="radio-items" v-for="(item, index) in languages" :key="index">
          <label class="containers">
            <input type="radio" class="hidden-radio" v-model="language" name="radio" :value="item" />
            <span class="label-name">{{item}}</span>
            <span class="checkmark"></span>
          </label>
          <!-- <input type="radio" name="" id="" />
          <label class="label">{{ item }}</label>-->
        </div>
      </div>
    </div>

    <div class="radio-type">
      <h4>Notifications</h4>
      <div class="radio-box">
        <div class="radio-items" v-for="(item, index) in notifications" :key="index">
          <label class="containers">
            <input
              type="radio"
              class="hidden-radio"
              v-model="notification"
              name="radio"
              :value="item"
            />
            <span class="label-name">{{item}}</span>
            <span class="checkmark"></span>
          </label>
          <!-- <input type="radio" name="" id="" />
          <label class="label">{{ item }}</label>-->
        </div>
      </div>
    </div>

    <div class="radio-type">
      <h4>Quality</h4>
      <div class="radio-box">
        <div class="radio-items" v-for="(item, index) in quality" :key="index">
          <label class="containers">
            <input
              type="radio"
              class="hidden-radio"
              v-model="qualityValue"
              name="radio"
              :value="item"
            />
            <span class="label-name">{{item}}</span>
            <span class="checkmark"></span>
          </label>
          <!-- <input type="radio" name="" id="" />
          <label class="label">{{ item }}</label>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      language: "",
      notification: "",
      qualityValue: "",
      languages: [],
      notifications: [],
      quality: []
    };
  },
  created() {
    this.languages = [
      "English",
      "Urdu",
      "Hindi",
      "Chinese",
      "Tamil",
      "Spanish"
    ];
    this.notifications = ["ON", "OFF"];
    this.quality = ["High", "Medium", "Low"];
  }
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";

.settings-tab {
  padding: 2rem;
  .radio-type {
    margin-bottom: 2.2rem;
    h4 {
      color: #efeff4;
      font-family: $font-regular;
      font-weight: bold;
    }
    .radio-box {
      display: flex;
      padding: 1.5rem 0px;
      .radio-items {
        padding-right: 1rem;
      }
      // .label {
      //   padding: 0px 40px 0px 10px;
      //   opacity: 0.4;
      //   color: #efeff4;
      //   font-family: "Helvetica Neue", sans-serif;
      //   font-size: 14px;
      //   letter-spacing: -0.15px;
      //   line-height: 20px;
      // }
    }
  }
}
</style>
